import {Box, Button, Typography} from '@mui/material'
import {Keyboard, Mouse } from '@mui/icons-material';
import Modal from "@mui/material/Modal";
import React from 'react';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    Height: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    backgroundColor: '#303030',
    color: 'whitesmoke',
};

const InputListPopUp : React.FC = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button
                color='primary'
                style={{
                    width: '9vh', height: '4vh', justifyContent: 'center', alignItems: 'center', 
                    backgroundColor: '#606060', borderColor: '#ffffff', borderStyle: 'solid', borderWidth: '2px', borderRadius: '5px'
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#808080';
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = '#606060';
                }}
                onClick={(e) => {
                    handleOpen();
                }}
            >
                <Keyboard style={{width: '5vh', height: '4vh', color: 'whitesmoke' }}/>
                <Mouse style={{width: '2vh', height: '1.6vh', color: 'whitesmoke' }}/>
            </Button>
            <Modal                
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        Controls
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        W A S D = Move
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Space = Jump
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Left Mouse / K = Shoot
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Right Mouse / L = Aim
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        P = Leaderboard, Pause and Settings
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        G = Pick up the gun
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        C = Crouch
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Shift = Sprint
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        1, 2, 3 = Change weapon
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        4, 5, 6 = Use scorestrikes
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        R = Reload
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Q = Tactical Weapon
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        E = Explosive Weapon
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default InputListPopUp