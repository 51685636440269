import { Fullscreen as FullscreenIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

const FullScreenButton: React.FC = () => {
    return (
        <div>
            <Button                
                style={{
                    width: '4vh', height: '4vh', 
                    //display: 'flex', justifyContent: 'center', alignItems: 'center',
                    //textAlign: 'center',
                     backgroundColor: '#606060', borderColor: '#ffffff', borderStyle: 'solid', borderWidth: '2px', borderRadius: '5px'}}
                onClick={() => {
                    const elem = document.getElementById('game-iframe');
                    elem?.requestFullscreen();
                }}
                onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#808080';
                }}
                onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = '#606060';
                }}
            >
                <FullscreenIcon style={{width: '3vh', height: '3vh', color: 'whitesmoke' }}/>
            </Button>
        </div> 
    )
  }

export default FullScreenButton