import { Feedback,} from "@mui/icons-material";
import { Box, Button, List, ListItem, ListItemButton, ListItemText, Drawer, Typography, TextField } from "@mui/material";
import React from "react";

const boxStyle = {
    width: 350,
    height: '100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    backgroundColor: '#303030',
    color: 'whitesmoke',
    textAlign: 'center' as 'center',
};

const buttonStyle ={
    textAlign: 'center' as 'center',
    color: 'whitesmoke',
    backgroundColor: '#606060', borderColor: '#ffffff', borderStyle: 'solid', borderWidth: '2px', borderRadius: '5px',
    alignItems: 'center' as 'center',
    height: '40px',
    margin: '5px'
}

const titleStyle = 
{
    textAlign: 'center',
    color: 'whitesmoke',
    fontSize: '2.5vh',
    fontWeight: 'bold' as 'bold',    
}

const textStyle ={
    textAlign: 'center',
    color: 'whitesmoke',
    fontSize: '2vh',
}

const InputTextStyle = {
    width: '100%',
    height: '54px',
    backgroundColor: '#606060',
    color: '#ffffff',
    labelColor: 'whitesmoke',
    textAlign: 'center' as 'center',
    fontColor: 'whitesmoke',
    borderColor: 'whitesmoke',
    fontSize: '2vh',
    margin: '5px',
    m: 1
}

const FeedbackPanel : React.FC = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
      };

    const DrawerList = (
        <Box sx={boxStyle} role="presentation">
            <List color="primary" >                
                <ListItemText primary={<Typography variant="h4">Feedback</Typography>}  sx={titleStyle} align-items={'center'}/>
                <ListItem >
                    <TextField id="outlined-multiline-flexible" sx={InputTextStyle} label="Your E-mail" maxRows={4}/>
                </ListItem>
                <ListItem>
                    <TextField id="outlined-multiline-flexible" sx={InputTextStyle} label="Your Feedback" multiline color="primary" maxRows={4}/>
                </ListItem>
                <ListItemButton sx={buttonStyle}>
                    <ListItemText primary="Send Feedback" sx={textStyle} align-items={'center'}/>
                </ListItemButton>                
            </List>            
        </Box>
    );

    return (        
        <div>
            <Button
                color='primary'
                style={{
                    width: '5vh', height: '4vh', justifyContent: 'center', alignItems: 'center', 
                    backgroundColor: '#606060', borderColor: '#ffffff', borderStyle: 'solid', borderWidth: '2px', borderRadius: '5px'
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#808080';
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = '#606060';
                }}
                onClick={toggleDrawer(true)}
            >
                <Feedback style={{width: '5vh', height: '3vh', color: 'whitesmoke' }}/>
            </Button>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>        
    );
}

export default FeedbackPanel;