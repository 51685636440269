import React, { ReactNode } from 'react'
import Footer from '../components/Footer'
import { ThemeProvider } from '@mui/material';
import theme from '../themes/theme';

interface MainLayoutProps {
    children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>        
        <div className="main-block">
            {children}            
        </div>
        <Footer/>
    </ThemeProvider>
  )
}

export default MainLayout
