import { createTheme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    // Your custom theme properties here
    customProperty: string;
  }
  interface Theme {
    // Your custom theme properties here
    customProperty: string;
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#yourPrimaryColor',
    },
    secondary: {
      main: '#yourSecondaryColor',
    },
  },
  // Your custom properties
  customProperty: 'yourCustomValue',
};

const theme = createTheme(themeOptions);

export default theme;
