import React from 'react'
import '../styles/footer.css'
import { Grid, TableFooter } from '@mui/material'
import FullScreenButton from './FullScreenButton'
import InputListPopUp from './InputListPopUp'
import FeedbackPanel from './FeedbackPanel'

const Footer: React.FC = () => {
  return (
    <TableFooter className='footer-block'>
        <div style={{width: '100vw', height: '100%'}}>
          <Grid container columnGap={2} justifyContent={'end'}>
              <FeedbackPanel/>
              <InputListPopUp/>
              <FullScreenButton/>
          </Grid>      
        </div>
    </TableFooter>
  )
}

export default Footer