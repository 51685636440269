import React from 'react'
import '../styles/footer.css'

const GameIFrame: React.FC = () => {
  return (
    <div style={{width: '100vw', height: '96vh', position: 'relative'}}>
        <iframe
            //className='align-content-lg-stretch'
            title='game'
            id='game-iframe'
            allowFullScreen = {true}
            //src='https://game.hazmob.io/'
            src='https://html5.gamedistribution.com/623baec23d504c83acab0b5250859ba8/?gd_sdk_referrer_url=https://hazmob.io'
            width="100%"
            height="100%"
            style={{border: 'none', position: 'relative'}}
        />
    </div> 
  )
}

export default GameIFrame