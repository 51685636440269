import React from 'react';
import MainLayout from '../layouts/MainLayout';
import GameIFrame from '../components/GameFrame';

export const Home = () => {
    return (
        <MainLayout>            
            <GameIFrame/>
        </MainLayout>
    )
}